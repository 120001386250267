@import "./kit/variables.scss";

* {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $color;
  background: linear-gradient($purple-dark, $purple);
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family-heading;
  margin: 0;
  padding: 0;
}

a {
  color: $link-color;
}
