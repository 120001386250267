@import "../../../styles/kit/variables";

.card {
  background-color: white;
  border-radius: $border-radius;
}

.cardHeader {
  padding: 1rem 2rem;
  border-bottom: 1px solid $gray-light;
}

.cardFooter {
  padding: 1rem 2rem;
  border-top: 1px solid $gray-light;
}

.cardBody {
  padding: 1rem 2rem;
}
