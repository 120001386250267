@import "../../../styles/kit/variables";

.button {
  display: block;
  padding: 0.8rem 0;
  text-align: center;
  text-decoration: none;
  font-size: 1.1rem;
  color: $teal-dark;
  background-color: $teal;
  border-radius: 5rem;
  font-weight: 500;

  &.margined {
    margin: 0 1rem;
  }

  > svg {
    vertical-align: middle;
    margin-top: -0.22rem;
    margin-right: 0.5rem;
  }
}
