@import url("https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&family=Fira+Sans:wght@400;500;600;700;800;900&family=Lato&display=swap");

$white: #fff;
$gray-lightest: #ebebeb;
$gray-lighter: #d4ced7;
$gray-light: #f1ecf4;
$gray: #c6c2c9;

$purple: #4d4364;
$purple-dark: #2c1a32;

$teal-dark: #125246;
//$teal-dark: #106C75;
$teal: #98d0c5;

$color: #28253c;
$link-color: #3053af;

$font-size-root: 16px;

$font-family-base: "Fira Sans Condensed";
$font-family-heading: "Fira Sans";
$font-size-base: 1rem;

$border-radius: 0.4rem;

// Forms & Inputs
$input-padding: 0.6rem;
$input-font-size: 1rem;

// Spacing
$spacing: 2rem;

:root {
  --purple-dark: $purple-dark;
  --purple: $purple;

  --teal: $teal;
  --teal-dark: $teal-dark;

  --color: $color;

  --font-family-base: $font-family-base;
  --font-family-heading: $font-family-heading;
  --font-size-base: $font-size-base;

  --border-radius: $border-radius;

  // Forms & Inputs
  --input-padding: $input-padding;
  --input-font-size: $input-font-size;
}
