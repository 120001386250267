@import "../../../styles/kit/variables";

@mixin chatTriggerIcon {
  content: "";
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 0.15rem;
  top: -0.5rem;
  background-color: $gray;
}

.situation {
  position: relative;
  height: 100%;
  overflow: hidden;

  .vortex,
  .vortexLayers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.8s ease-in-out;
  }

  .vortex {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    button {
      background-color: transparent;
      border: none;

      img {
        width: 100%;
      }
    }

    &.isExpanded {
      transform: translateY(10rem) scale(2);
      opacity: 0;
      z-index: 1;
    }
  }

  .vortexLayers {
    opacity: 0;
    transform: translateY(-3rem) scale(0.9);
    z-index: 1;

    &.isExpanded {
      opacity: 1;
      transform: translateY(0) scale(1);
      z-index: 3;
    }

    :global(.swiper) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    :global(.swiper-slide) {
    }
  }
}

.chatBox {
  $chat-box-height: 80vh;

  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: $chat-box-height;
  bottom: -$chat-box-height;
  z-index: 3;
  transition: bottom 0.25s cubic-bezier(0.675, 0.025, 0.42, 0.97);

  &.isVisible {
    bottom: 0;
  }

  .chatBoxCollapseTrigger {
    flex: 0 0 1rem;
    border: none;
    background-color: $gray-lightest;
    padding: 1rem;
    border-radius: 1rem 1rem 0 0;

    &:before {
      @include chatTriggerIcon;
    }
  }

  .body {
    flex: 1 0 0;
    background-color: $gray-lightest;
    height: 100%;
    overflow: auto;
  }

  .inputBox {
    display: flex;
    flex: 0 0 1rem;
    background-color: $gray-lightest;
    padding: 1rem 1rem;

    input {
      flex: 1 0 0;
      margin: 0 0.3rem 0 0;
      border-radius: 1.5rem;
    }

    button {
      flex: 0 0 3rem;
      height: 3rem;
      background-color: $teal-dark;
      border-radius: 50%;
      border: none;

      svg {
        position: relative;
        top: 3px;
        left: -1px;
        //margin: .2rem 0 0 0;
        stroke: #fff;
      }
    }
  }
}

.chatBoxExpandTrigger {
  position: absolute;
  bottom: -4.5rem;
  width: 100%;
  z-index: 4;
  transition: bottom 0.25s cubic-bezier(0.675, 0.025, 0.42, 0.97);

  &.isVisible {
    bottom: 0;
  }

  button {
    display: block;
    width: 100%;
    padding: 1rem 0 1.5rem;
    border-radius: 1rem 1rem 0 0;
    border: none;
    background-color: #fff;

    &:before {
      @include chatTriggerIcon;
    }
  }
}

////////////////

.situationssss {
  $conversation-box-height: 80vh;

  position: relative;
  height: 100%;
  overflow: hidden;

  .trigger {
    display: block;
    width: 100%;
    text-align: center;
    border: none;
    padding: 0.5rem;
    background-color: $gray-lightest;
    border-radius: 2rem 2rem 0 0;

    &::before {
      content: "";
      display: inline-block;
      width: 5rem;
      height: 0.15rem;
      background-color: $gray;
    }

    .label {
      margin: 0.5rem 0;
    }
  }

  .conversation {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: $conversation-box-height;
    bottom: 0;
    transition: bottom 0.25s cubic-bezier(0.675, 0.025, 0.42, 0.97);

    .trigger {
      flex: 0 0 1rem;
    }

    .body {
      flex: 1 0 0;
      background-color: $gray-lightest;
      height: 100%;
    }

    .messageBox {
      display: flex;
      flex: 0 0 1rem;
      background-color: $gray-lightest;
      padding: 1rem 1rem;

      input {
        flex: 1 0 0;
        margin: 0 0.3rem 0 0;
        border-radius: 1.5rem;
      }

      button {
        flex: 0 0 3rem;
        height: 3rem;
        background-color: $teal-dark;
        border-radius: 50%;
        border: none;

        svg {
          position: relative;
          top: 3px;
          left: -1px;
          //margin: .2rem 0 0 0;
          stroke: #fff;
        }
      }
    }
  }

  &.conversationCollapsed .conversation {
    bottom: -$conversation-box-height;
  }

  .expand {
    position: absolute;
    bottom: -4rem;
    width: 100%;

    transition: bottom 0.25s cubic-bezier(0.675, 0.025, 0.42, 0.97);
    transition-delay: 0s;

    .trigger {
      border-radius: 2rem 2rem 0 0;

      .trigger-icon:before {
        content: "";
        display: inline-block;
        width: 4rem;
        height: 0.5rem;
        background-color: #ccc;
      }
    }
  }

  &.conversationCollapsed .expand {
    bottom: 0;
    transition-delay: 0.1s;
  }
}

.bubbles {
  display: flex;
  flex-direction: column-reverse;
  padding: 0 1rem;

  .bubbleBox {
    margin-bottom: 1rem;

    .bubble {
      float: right;
      display: inline-block;
      padding: 1rem;
      background-color: $teal;
      color: $teal-dark;
      border-radius: 1rem 1rem 0 1rem;
      max-width: 90%;
    }

    &.isAffi {
      .bubble {
        float: left;
        background: #fff;
        border-radius: 1rem 1rem 1rem 0;
      }
    }
  }
}
