@import "../../../styles/kit/variables";

.input {
  display: block;
  width: 100%;
  padding: $input-padding;
  margin-bottom: 0.75rem;
  background-color: #fff;
  font-size: $input-font-size;
  border: 1px solid $gray-lighter;
  border-radius: 0.2rem;

  &:disabled {
    background-color: $gray-light;
  }
}
