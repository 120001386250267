@import "../../../../styles/kit/variables";

$layer-one-color: #106c75;
$layer-two-color: #3a7a84;
$layer-three-color: #98d0c5;
$layer-four-color: #c26b61;
$layer-five-color: #e0a681;
$layer-six-color: #b38de4;

$chevron-color: #9079b8;

// TODO: Center align vortex
// TODO: Trigger expanded view

.vortex {
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;

  :global(.swiper) {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  :global(.swiper-slide) {
  }

  &.isExpanded .vortexExpand {
    transform: translateY(30rem) scale(3);
    opacity: 0;
  }

  .expanded {
    position: absolute;
    top: 0;
    opacity: 1;
    //display: none;
    height: 100%;
    //border: 1px solid red;
    //background-color: green;
    transform: scale(0.5);
    transition: all 0.5s ease-in-out;
  }

  &.isExpanded .collapsed {
    opacity: 0;
  }

  &.isExpanded .expanded {
    opacity: 1;
    //display: block;
    transform: scale(1);
  }
}

.vortexExpand {
  position: absolute;
  top: 0;
  height: 100%;
  background: transparent;
  border: none;
  transition: all 0.5s ease-in-out;
  z-index: 1;

  img {
    width: 100%;
  }
}

.layer {
  display: flex;
  flex-direction: column;
  height: 100%;

  color: #fff;

  .layerPrev,
  .layerCurrent,
  .layerNext {
    flex: 1 0 0;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: inline-block;
      stroke: $chevron-color;
    }
  }

  .layerPrev,
  .layerNext {
    h2 {
      font-size: 1rem;
    }
  }

  .layerCurrent {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }

  h2 {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  p {
    padding: 0 2rem;
    font-weight: 400;
    font-size: 1.8rem;
  }

  .helpStart {
    margin: 0 6rem;
    font-size: 0.8rem;
  }

  &.layerOne {
    .layerCurrent {
      background-image: url("./images/layerOne.png");

      h2 {
        color: $layer-one-color;
      }
    }
    .layerNext {
      h2 {
        color: $layer-two-color;
      }
    }
  }
  &.layerTwo {
    .layerPrev {
      h2 {
        color: $layer-one-color;
      }
    }
    .layerCurrent {
      background-image: url("images/layerTwo.png");

      h2 {
        color: $layer-two-color;
      }
    }
    .layerNext {
      h2 {
        color: $layer-three-color;
      }
    }
  }
  &.layerThree {
    .layerPrev {
      h2 {
        color: $layer-two-color;
      }
    }
    .layerCurrent {
      background-image: url("./images/layerThree.png");

      h2 {
        color: $layer-three-color;
      }
    }
    .layerNext {
      h2 {
        color: $layer-four-color;
      }
    }
  }
  &.layerFour {
    .layerPrev {
      h2 {
        color: $layer-three-color;
      }
    }
    .layerCurrent {
      background-image: url("./images/layerFour.png");

      h2 {
        color: $layer-four-color;
      }
    }
    .layerNext {
      h2 {
        color: $layer-five-color;
      }
    }
  }
  &.layerFive {
    .layerPrev {
      h2 {
        color: $layer-four-color;
      }
    }
    .layerCurrent {
      background-image: url("./images/layerFive.png");

      h2 {
        color: $layer-five-color;
      }
    }
    .layerNext {
      h2 {
        color: $layer-six-color;
      }
    }
  }
  &.layerSix {
    background-image: url("./images/layerSix.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;

    .layerPrev {
      h2 {
        color: $layer-five-color;
      }
    }
    .layerCurrent {
      //background-image: url('./images/layerFive.png');

      h2 {
        color: $layer-six-color;
      }
    }
    //.layerNext {
    //  h2 {
    //    color: $layer-six-color;
    //  }
    //}
  }
}
