@import "../../../styles/kit/variables";

.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 4rem;
  margin-top: 2rem;
}

.authCard {
  margin: 2rem;
}

h1 {
  font-size: 1.7rem;
  margin: 0;
  text-align: center;
  font-weight: normal;
}

p {
  margin: 0;
  text-align: center;
}

.forgotPassword,
.signup {
  display: block;
  margin: -0.15rem 0 1.5rem;
  font-size: 0.9rem;
}

.signup {
  margin-left: 0.2rem;
}

.signupOption {
  text-align: left;
  font-size: 0.8rem;

  a {
    display: inline;
  }
}
