@import "./src/styles/kit";
$min-width-tablet: 48rem;
$min-width-desktop: 62rem;

$container-width: 100%;
$container-width-tablet: 100%;
$container-width-desktop: 62rem;
$grid-columns: 12;
$grid-gutter: 1rem; // space between columns
@mixin tablet-and-up {
  @media (min-width: $min-width-tablet) {
    @content;
  }
}

@mixin desktop-and-up {
  @media (min-width: $min-width-desktop) {
    @content;
  }
}
.container {
  width: 100%;
  margin: 0 auto;

  @include tablet-and-up {
    width: 100%;
  }

  @include desktop-and-up {
    width: 62rem;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 $grid-gutter / 2;

  &.reverseRow {
    flex-direction: row-reverse;
  }
}

.col {
  flex: 1 0 0;
  padding: 0 $grid-gutter / 2;

  @for $index from 1 through $grid-columns {
    &.col#{$index} {
      @media (max-width: $min-width-tablet) {
        flex: 1 0 auto;
        width: calc(100% * $index / $grid-columns);
        max-width: calc(100% * $index / $grid-columns);
      }
    }

    &.colTablet#{$index} {
      @media (min-width: $min-width-tablet) and (max-width: $min-width-desktop) {
        flex: 1 0 auto;
        width: calc(100% * $index / $grid-columns);
        max-width: calc(100% * $index / $grid-columns);
      }
    }

    &.colDesktop#{$index} {
      @media (min-width: $min-width-desktop) {
        flex: 1 0 auto;
        width: calc(100% * $index / $grid-columns);
        max-width: calc(100% * $index / $grid-columns);
      }
    }
  }
}
