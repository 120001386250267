
@import '../../../styles/kit/variables';

.home {
    background: linear-gradient(#2c1a32,#4d4364);
    width: 100%;
    height: 100%;
    vertical-align: middle;
    color: white;
    .homeContainer {
        $padding-vertical: calc((100vh - 550px) / 2);
        padding-top: $padding-vertical;
        @media (max-width: 640px){
            padding-top: 100px;
        }
        padding-bottom:$padding-vertical;
        height: 100vh;
        vertical-align: middle;
        text-align: center;
        h1 {
            margin-top: 2rem;
            margin-bottom: 1rem;
            font-size: 60px;
            text-align: center;
            line-height: 1.1;
            font-family: 'Lato', sans-serif;

        }
        h2 {
            font-family: 'Lato', sans-serif;
            margin-bottom: 1.5rem;
            color: $teal;
        }
        h4 {
            font-weight: normal;
            font-family: 'Lato', sans-serif;
        }
        .buttons{
            margin-bottom: 2rem;
            .discover{
                width: auto;
                height: auto;
                padding: 10px 20px 10px 20px;
                margin-right: 30px;
                background-color: black;
                color: white;
                font-family: "Work Sans", "Work Sans Placeholder", sans-serif;
                font-size: 20px;
                font-weight: normal;
                line-height: 1.5em;
                border-radius: 30%;
                border: none;
                box-shadow: none;
            }
            .waitlist {
                font-size: 20;
                font-family: "work Sans";
                color: black;

            }
        }
    }
    h4 {
      font-weight: normal;
      font-family: "Lato", sans-serif;
    }
    .buttons {
      margin-bottom: 2rem;
      .discover {
        width: auto;
        height: auto;
        padding: 10px 20px 10px 20px;
        margin-right: 30px;
        background-color: black;
        color: white;
        font-family: "Work Sans", "Work Sans Placeholder", sans-serif;
        font-size: 20px;
        font-weight: normal;
        line-height: 1.5em;
        border-radius: 30%;
        border: none;
        box-shadow: none;
      }
      .waitlist {
        font-size: 20;
        font-family: "work Sans";
        color: black;
      }
    }
}
