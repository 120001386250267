@import "../../../styles/kit/variables";

.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  //$spacing: 2rem;
  //
  //display: grid;
  //grid-template-rows: 1fr 1rem;
  //grid-gap: $spacing;
  //padding: $spacing;
  //height: 80vh;

  //border-radius: 0.2rem;
}

.header {
  padding: 1rem 2rem 0;

  h1 {
    text-align: left;
    margin: 0;
  }
}

.body {
  overflow: auto;
  max-height: 100%;
  padding: 2rem;
}

.situationCard {
  margin-bottom: 1rem;
  border-bottom: 1px solid $gray;
  padding-bottom: 1rem;
  display: flex;
}

.situationCardGroup {
  flex: 2rem 0 0;

  .group {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
  }
}
.situationCardBody {
  flex: 1 0 auto;
}

.loggedDate {
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  margin-bottom: 0.3rem;
}

.footer {
  padding: 2rem;
}
